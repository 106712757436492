<template>
  <ion-list>
    <ion-item v-for="request in group.join_requests" v-bind:key="request.id">
      <ion-avatar
        slot="start"
        style="
          cursor: pointer;
          max-width: 30px;
          max-height: 30px;
          margin-top: 0px;
          margin-left: 15px;
          padding-top: 0px;
        "
        class="comment-avatar"
        @click="this.$router.push('/user/profile/' + request.tokenized_user)"
      >
        <ion-img
          style="
            max-width: 30px;
            max-height: 30px;
            margin-top: 10px;
            padding-top: 0px;
          "
          v-if="!request.profile_photo || !request.profile_photo.photo_thumbnail"
          src="assets/icon/misc/group.svg"
        ></ion-img>
        <ion-img
          style="
            max-width: 30px;
            max-height: 30px;
            margin-top: 10px;
            padding-top: 0px;
          "
          v-if="request.profile_photo && request.profile_photo.photo_thumbnail"
          :src="request.profile_photo.photo_thumbnail"
        ></ion-img>
      </ion-avatar>
      <ion-label color="dark" style="margin-left: 55px; min-height: 35px;">
        <small @click="this.$router.push('/user/profile/' + request.tokenized_user)">
          <b style="cursor: pointer;"> {{  request.requestd_by_name }} </b><br/>
        </small>
        <ion-buttons class="right-margin-15px top-margin-10px">
          <ion-button
            size="small"
            fill="outline"
            shape="round"
            color="dark"
            v-if="isCurrentUserAdmin"
            @click="acceptRequest(request)"
            :disabled="joinRequestClicked"
          >
              <small>{{ getStrings(strings, "Accept") }}</small>
          </ion-button>
          <ion-button
            size="small"
            fill="outline"
            shape="round"
            color="dark"
            v-if="isCurrentUserAdmin"
            @click="rejectRequest(request)"
            :disabled="joinRequestClicked"
          >
            <small>{{ getStrings(strings, "Reject") }}</small>
          </ion-button>
        </ion-buttons>
      </ion-label>
      <ion-spinner
        v-if="joinRequestClicked"
        slot="end"
        name="dots"
      ></ion-spinner>
    </ion-item>
  </ion-list>
</template>

<script>
import {
  IonList,
  IonItem,
  IonLabel,
  IonButtons,
  IonButton,
  IonSpinner,
  IonImg,
  IonAvatar,
  toastController,
  } from "@ionic/vue";
import { useRouter } from "vue-router";

import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
} from "../../services/utils";

import { getStrings } from "../../services/lang";
import { acceptGroupJoin ,rejectGroupJoin } from "../../services/apiCall";

export default {
  name: "ManageJoinRequests",
  props: ["group", "refreshSettingCallback"],
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonButtons,
    IonButton,
    IonSpinner,
    IonImg,
    IonAvatar,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
      acceptGroupJoin,
      rejectGroupJoin,
      getStrings,
    };
  },
  data() {
    return {
      joinRequestClicked: false,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
    isCurrentUserAdmin() {
      return (
        this.group.info.is_group_member &&
        this.group.info.group_member_type === "Admin"
      );
    },
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    acceptRequest(request) {
      console.log("removeUser", request);
      this.joinRequestClicked = true;
      acceptGroupJoin({ join_request_id: request.id }).then((res) => {
        console.log('acceptGroupJoin', res);
        if (res.data.status === 0) {
          this.joinRequestClicked = false;
          this.refreshSettingCallback();
        }
      }).catch((err) => {
        console.log('acceptGroupJoin - err', err);
        this.joinRequestClicked = false;
      });
    },
    rejectRequest(request) {
      console.log("makeAdmin", request);
      this.joinRequestClicked = true;
      rejectGroupJoin({ join_request_id: request.id }).then((res) => {
        console.log('rejectGroupJoin', res);
        if (res.data.status === 0) {
          console.log('Request rejected');
          this.joinRequestClicked = false;
          this.refreshSettingCallback();
        }
      }).catch((err) => {
        console.log('rejectGroupJoin - err', err);
        this.joinRequestClicked = false;
      });
    },
  },
};
</script>