<template>
  <ion-row class="ion-no-padding ion-no-pargin">
    <ion-col class="ion-no-padding ion-no-pargin">
      <ion-item class="ion-no-padding ion-no-pargin" no-lines lines="none">
        <group-popover-button
          :group="group"
          :pageAction="pageAction"
          v-if="group.info.is_group_member"
        >
        </group-popover-button>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row class="ion-no-padding ion-no-pargin">
    <ion-col class="ion-no-padding ion-no-pargin">
      <group-setting-head :group="group"></group-setting-head>
    </ion-col>
  </ion-row>
  <!-- Update group -->
  <ion-row class="ion-no-padding ion-no-pargin" v-if="pageAction === 'edit'">
    <ion-col class="ion-no-padding ion-no-pargin">
      <group-update-form :group="group"></group-update-form>
    </ion-col>
  </ion-row>
  <!-- show post writing box if a group member -->
  <span v-if="pageAction !== 'edit' && pageAction !== 'manage'">
    <ion-row
      class="ion-vertical-padding ion-no-margin animate-fading"
      v-if="group.info.is_group_member && (group.info.visibility_type !== 'Broadcast' || group.info.created_by === user.user_id)"
    >
      <ion-col
        class="ion-no-padding ion-no-margin"
        style="margin-left: 5px; margin-right: 5px"
      >
        <br />
        <create-post-form
          @save-post="savePost"
          :restrictSelection="topicsPerPostLimit"
          :topics="createPostTopics"
          :group="group"
          visibilityMode="Group"
          postPlaceHolderMessage="Message your group"
          enableMentions="true"
          :mentionsSuggessionsCallback="getMentionsList"
        ></create-post-form>
      </ion-col>
    </ion-row>
    <!-- show opitions to join if group joining type is not Invite only -->
    <ion-row
      class="ion-vertical-padding ion-no-margin animate-fading"
      v-if="!group.info.is_group_member && group.infoFetched"
    >
      <ion-col class="ion-no-padding ion-no-margin">
        <ion-button
          v-if="
            group.info.joining_by !== 'Invitation' &&
            !group.isRequestSent &&
            !group.info.has_active_join_request
          "
          @click="joinGroup(group)"
          :disabled="joinGroupClicked"
          expand="block"
          >Join Group To Post</ion-button
        >
        <ion-button
          v-if="
            group.info.joining_by !== 'Invitation' && group.has_active_invite
          "
          @click="acceptInite(group)"
          :disabled="joinGroupClicked"
          expand="block"
        >
          Accpet to Join Group
        </ion-button>
        <ion-label
          v-if="group.isRequestSent || group.info.has_active_join_request"
        >
          Group Request Sent
        </ion-label>
        <ion-label v-if="group.info.joining_by === 'Invitation'"
          >Member by Invite only</ion-label
        >
      </ion-col>
    </ion-row>
    <!-- show posts only if group member or a Public group -->
    <ion-row
      class="ion-vertical-padding ion-no-margin animate-fading"
      v-if="
        group.info.is_group_member || group.info.visibility_type === 'Public'
      "
    >
      <ion-col class="ion-no-padding ion-no-margin">
        <br />
        <ion-item
          v-if="group.inPostRefreshMode && posts.length === 0"
          class="animate-fading"
          no-lines
          lines="none"
        >
          <ion-thumbnail slot="start">
            <ion-skeleton-text></ion-skeleton-text>
          </ion-thumbnail>
          <ion-label>
            <h3>
              <ion-skeleton-text
                animated
                style="width: 80%"
              ></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text
                animated
                style="width: 60%"
              ></ion-skeleton-text>
            </p>
            <p>
              <ion-skeleton-text
                animated
                style="width: 30%"
              ></ion-skeleton-text>
            </p>
          </ion-label>
          <br /><br />
        </ion-item>
        <posts-list
          :posts="posts"
          :fetchPostAPI="listPosts"
          :scrollState="scrollState"
        ></posts-list>
      </ion-col>
    </ion-row>
  </span>
  <ion-row
    class="ion-vertical-padding ion-no-margin animate-fading"
    v-if="pageAction === 'manage'"
  >
    <ion-col class="ion-no-padding ion-no-margin">
      <group-settings
        :group="group"
        :refreshOverviewCallback="refreshOverviewCallback"
      ></group-settings>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonItem,
  IonButton,
  IonLabel,
  IonSkeletonText,
  IonThumbnail,
  toastController,
} from "@ionic/vue";
import { useRouter } from "vue-router";

import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
} from "../../services/utils";

import { getStrings } from "../../services/lang";

import {
  topicList,
  listPosts,
  requestJoinGroup,
  acceptGroupJoin,
  muteGroup,
} from "../../services/apiCall";

import CreatePostForm from "../posts/CreatePostForm";
import PostsList from "../posts/PostList";
import GroupSettings from "./GroupSettings.vue";
import GroupSettingHead from "./GroupSettingHead.vue";
import GroupUpdateForm from "./GroupUpdateForm.vue";
import GroupPopoverButton from "./GroupPopoverButton.vue";

export default {
  name: "GroupOverview",
  props: ["group", "refreshGroupCallback", "scrollState", "pageAction"],
  mounted() {
    this.init();
  },
  components: {
    IonRow,
    IonCol,
    IonItem,
    IonButton,
    IonLabel,
    IonSkeletonText,
    IonThumbnail,
    CreatePostForm,
    PostsList,
    GroupSettings,
    GroupSettingHead,
    GroupUpdateForm,
    GroupPopoverButton,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
      topicList,
      listPosts,
      getStrings,
    };
  },
  data() {
    return {
      createPostTopics: [],
      topicsPerPostLimit: 2,
      joinGroupClicked: false,
      isAdminUser: false,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
    posts() {
      // console.log("posts", this.$store.getters.posts);
      return this.$store.getters.posts.filter(
        (p) => p.post_origin_tag === "Group"
      );
    },
    currentGroupMember() {
      return this.$store.getters.currentGroupMember;
    },
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    init() {
      console.log("User:", this.user);
      this.joinGroupClicked = false;
      topicList()
        .then((res) => {
          // console.log("topicList", res);

          if (res.data.status === 0) {
            this.$store.dispatch("settopiclist", res.data.result.topic_list);

            this.createPostTopics = JSON.parse(
              JSON.stringify(this.$store.getters.topicList)
            );
          }
        })
        .catch((err) => {
          console.log("GOV topicList - err", err);
        });
    },
    isAdmin() {
      console.log(
        "isAdmin called",
        this.group.info.is_group_member,
        this.group.info.group_member_type
      );
      return this.group.info.group_member_type === "Admin";
    },
    savePost(postData) {
      console.log("GOV savePost");
      this.$store.dispatch("addpost", postData);
    },
    joinGroup(group) {
      if (!group.info.has_active_join_request) {
        this.joinGroupClicked = true;
        requestJoinGroup({ group_id: group.group_id })
          .then((res) => {
            console.log("GOV requestJoinGroup", res);
            if (res.data.status === 0) {
              if (res.data.result.is_member) {
                group.isRequestSent = false;
                this.refreshOverviewCallback();
              } else {
                group.isRequestSent = true;
              }
            } else {
              this.openToast(res.data.error.error_message);
            }
            this.joinGroupClicked = false;
          })
          .catch((err) => {
            console.log("GOV requestJoinGroup - err", err);
            this.joinGroupClicked = false;
            this.openToast(
              this.getStrings(this.strings, "UnableToJoinRequestTryAgain")
            );
          });
      } else {
        this.openToast(this.getStrings(this.strings, "JoinGroupRequestSent"));
      }
    },
    acceptInvite(group) {
      this.joinGroupClicked = true;
      acceptGroupJoin({
        group_id: group.group_id,
      })
        .then((res) => {
          if (res.data.status === 0) {
            group.isRequestSent = true;
          } else {
            this.openToast(res.data.error.error_message);
          }
          this.joinGroupClicked = false;
        })
        .catch((err) => {
          console.log("GOV acceptGroupJoin - err", err);
          this.joinGroupClicked = false;
          this.openToast(
            this.getStrings(this.strings, "UnableToJoinRequestTryAgain")
          );
        });
    },
    muteTheGroup(group) {
      console.log("GOV muteTheGroup", group);

      // TODO: check with mute API
      muteGroup({ group_id: group.group_id })
        .then((res) => {
          console.log("GOV muteGroup", res);

          if (res.data.status === 0) {
            group.info.is_muted = res.data.result.is_muted;

            if (group.info.is_muted) {
              this.openToast(
                this.getStrings(this.strings, "NewGroupPostsNotificationsMuted")
              );
            } else {
              this.openToast(
                this.getStrings(
                  this.strings,
                  "NewGroupPostsNotificationsUnMuted"
                )
              );
            }
          } else {
            this.openToast(res.data.error.error_message);
          }
        })
        .catch((err) => {
          console.log("GOV muteGroup - err", err);
        });
    },
    refreshOverviewCallback() {
      this.refreshGroupCallback();
    },
    getMentionsList(searchText) {
      console.log("mention filter", searchText);
      if (!searchText || searchText.trim() === "") {
        return this.group.members;
      } else {
        return this.group.members.filter(
          (x) => x.full_name.indexOf(searchText) >= 0
        );
      }
    },
  },
};
</script>