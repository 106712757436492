<template>
  <ion-content>
    <ion-list lines="none">
      <ion-item
        v-for="action in moreActions"
        :key="action"
        :value="action"
        class="ion-padding-horizontal popover_item_style"
      >
        <ion-content>
          <ion-item class="popover_item_style">
            <ion-icon
              size="small"
              color="dark"
              :icon="optionIcons[action.id]"
            ></ion-icon>
            <ion-button
              color="dark"
              fill="clear"
              size="small"
              style="text-transform: none"
              @click="handleAction(group, action.id)"
              :disabled="action.id==='REPORT'"
            >
              {{ action.name }}
            </ion-button>
          </ion-item>
        </ion-content>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonItem,
  IonList,
  IonButton,
  IonIcon,
  toastController,
  alertController,
} from "@ionic/vue";

import { useRouter } from "vue-router";

import {
  notificationsOutline,
  notificationsOffOutline,
  flagOutline,
  logOutOutline,
  settingsOutline,
  createOutline,
} from "ionicons/icons";

import { defineComponent } from "vue";
import { leaveGroup, muteGroup } from "../../services/apiCall";
import { getStrings } from "../../services/lang";

export default defineComponent({
  name: "UserList",
  props: ["moreActions", "group", "parent"],
  components: {
    IonContent,
    IonItem,
    IonList,
    IonButton,
    IonIcon,
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getStrings,
    };
  },
  data() {
    return {
      notificationsOutline,
      notificationsOffOutline,
      flagOutline,
      logOutOutline,
      settingsOutline,
      createOutline,
      optionIcons: {
        'MUTE_GROUP': notificationsOutline,
        'UNMUTE_GROUP': notificationsOffOutline,
        'LEAVE_GROUP': logOutOutline,
        'REPORT': flagOutline,
        'MANAGE_GROUP': settingsOutline,
        'EDIT_GROUP': createOutline,
      }
    };
  },
  methods: {
    handleAction(group, actionId) {
      if (actionId === "MUTE_GROUP" || actionId === "UNMUTE_GROUP") {
        this.muteTheGroup(group);
      } else if (actionId === "LEAVE_GROUP") {
        this.leaveTheGroup(group);
      } else if (actionId === "REPORT") {
        this.openToast('Report not yet supported');
      } else if (actionId === "MANAGE_GROUP") {
        this.navigateManageGroup();
      } else if (actionId === "EDIT_GROUP") {
        this.navigateEditGroup();
      }
      this.parent.dismiss();
    },
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    muteTheGroup(group) {
      console.log('muteTheGroup', group);

      muteGroup({group_id: group.group_id}).then((res) => {
        console.log("muteGroup", res);

        if (res.data.status === 0) {
          group.info.is_muted = res.data.result.is_muted;

          if (group.info.is_muted) {
            this.openToast(this.getStrings(this.strings, "NewGroupPostsNotificationsMuted"));
          } else {
            this.openToast(this.getStrings(this.strings, "NewGroupPostsNotificationsUnMuted"));
          }
        } else {
          this.openToast(res.data.error.error_message);
        }
      }).catch((err) => {
        console.log("muteGroup - err", err);
      });
    },
    async leaveTheGroup(group) {
      console.log("leaveTheGroup", group);

      const anAlert = await alertController.create({
        title: "Confirm Leave Group",
        message:
          '<span style="text-align: justify; text-justify: inter-word;">Confirm that you want to leave the group.<br/><small>For regulatory requirement, the content you posted in the group may still be archived for sometime as per local laws.</small><br/><br/><b>Do you really want to leave the group?</b></span>',
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              leaveGroup({ group_id: group.group_id })
                .then((res) => {
                  console.log("You left:", res);
                  if (res.data.status === 0) {
                    group.is_left = true;
                    group.is_left_message = "You have left this Group.";
                    this.openToast(group.is_left_message);
                    this.router.replace("/posts");
                  } else {
                    this.openToast(res.data.error.error_message);
                  }
                })
                .catch((err) => {
                  console.log("leaveGroup - err", err);
                });
            }, // end handler
          },
        ],
      });
      await anAlert.present();
    },
    async navigateManageGroup() {
      await this.router.push("/groups/" + this.group.group_id + "/manage");
    },
    async navigateEditGroup() {
      await this.router.push("/groups/" + this.group.group_id + "/edit");
    }
  },
});
</script>
