<template>
  <!-- group header -->
  <ion-item
    no-lines
    lines="none"
    class="
      ion-justify-content-center
      ion-align-items-center
      ion-no-padding
      ion-no-margin
      animate-fading-2
    "
  >
    <ion-avatar
      size="large"
      class="animate-fading group-avatar"
      style="min-width: 100px; min-height: 100px; margin-top: 5px; cursor: pointer;"
      @click="showZoomedPhoto(group.profile_photo.photo)"
    >
      <ion-img v-if="!group.profile_photo.photo" src="assets/icon/misc/group.svg"></ion-img>
      <ion-img v-if="group.profile_photo.photo" :src="group.profile_photo.photo"></ion-img>
      <ion-spinner v-if="uploadingPhoto" slot="end" name="dots"></ion-spinner>
      <ion-button
        fill="clear"
        expand="full"
        @click="takePhoto(group)"
        style="text-transform: none"
        v-if="isAdmin()"

      >
          <ion-icon :icon="icons.cameraOutline" ></ion-icon>&nbsp;{{ getStrings(strings, "UpdatePhoto") }}
          <ion-spinner v-if="uploadingPhoto" slot="end" name="dots"></ion-spinner>
      </ion-button>
    </ion-avatar>
    <ion-text
      style="
        padding-left: 120px;
        min-height: 135px;
        margin-left: 15px;
        margin-right: 10px;
        padding-right: 10px;
        overflow: hidden;
      "
    >
      <span class="br">
        <ion-icon :icon="group.info.visibility_type === 'Closed' ? icons.lockClosedOutline : icons.lockOpenOutline"> </ion-icon>
        <b>&nbsp;{{ group.info.group_name }}</b>
      </span>
      <ion-label class="comment_text_style ion-text-wrap">
        {{ group.info.description }}
      </ion-label>
      <br />
      <ion-label
        class="comment_text_style"
        style="text-align: left;"
        v-if="
          (group.info.is_group_member || group.info.visibility_type === 'Public') &&
          group.info.visibility_type !== 'Broadcast'
        "
      > 
        <ion-text 
          @click="group.info.is_group_member ? navigateManageGroup() : ''"
          style="cursor: pointer;" color="primary"
        ><b> {{ group.info.member_count }}</b> {{ getStrings(strings, "Members") }}</ion-text>
      </ion-label>
      <br />
    </ion-text>
  </ion-item>
</template>

<script>
import {
  IonItem,
  IonIcon,
  IonAvatar,
  IonButton,
  IonSpinner,
  IonImg,
  IonLabel,
  IonText,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";

import ApplyFilterModal from "../media/ApplyFilterModal.vue";

import { cameraOutline, personCircleOutline, lockClosedOutline, lockOpenOutline } from "ionicons/icons";

import Jimp from "jimp";

const { Camera } = Plugins;

import { updateGroupProfilePhoto, groupProfilePhoto } from "../../services/apiCall";
import { profileImageSize, preFormat } from "../../services/utils";
import { getStrings } from "../../services/lang";
import PostImageModal from "../posts/PostImageModal.vue";

export default defineComponent({
  name: "GroupSettingHead",
  props: ["group"],
  components: {
    IonItem,
    IonIcon,
    IonAvatar,
    IonButton,
    IonSpinner,
    IonImg,
    IonLabel,
    IonText,
  },
  data() {
    return {
      icons: {
        cameraOutline,
        personCircleOutline,
        lockClosedOutline,
        lockOpenOutline,
      },
      currentGroupProfile: {
        photo: "",
        photo_thumbnail: "",
      },
      uploadingPhoto: false,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  setup() {
    const router = useRouter();
    return {
      router,
      updateGroupProfilePhoto,
      groupProfilePhoto,
      preFormat,
      getStrings,
    };
  },
  methods: {
    setPhoto() {
      this.currentGroupProfile.photo = this.group.profile_photo.photo;
    },
    isAdmin() {
      return (
        this.group.info.is_group_member &&
        this.group.info.group_member_type === "Admin"
      );
    },
    async takePhoto(group) {
      try {
        console.log("GPH updateGroupProfilePhoto - call", this.group);

        this.uploadingPhoto = true;

        const imgSize = profileImageSize();

        // take the photo
        const photo = await Camera.getPhoto({
          resultType: CameraResultType.Uri,
          source: CameraSource.Prompt,
          width: imgSize,
          preserveAspectRatio: true,
          correctOrientation: true,
        });

        // rescale to 300px
        let imageObj = await Jimp.read(photo.webPath);
        let ratio = imgSize / imageObj.getWidth();
        let scaledImgObj = await imageObj.scale(ratio);

        let imgB64 = await scaledImgObj.getBase64Async("image/png");
        this.currentGroupProfile.photo = imgB64;

        this.uploadingPhoto = false;

        // filter call back
        var filterCallback = (filteredImg) => {
          this.uploadingPhoto = true;

          this.currentGroupProfile.photo = filteredImg;
          this.currentGroupProfile.photo_thumbnail = filteredImg;

          console.log("GPH updateGroupProfilePhoto - call", this.group);

          updateGroupProfilePhoto({
            group_id: this.group.group_id,
            photo: this.currentGroupProfile.photo,
          })
            .then((res) => {
              console.log("GPH updateGroupProfilePhoto", res);
              group.profile_photo.photo = this.currentGroupProfile.photo;
              this.uploadingPhoto = false;
            })
            .catch((err) => {
              console.log("GPH updateGroupProfilePhoto - err", err);
              this.uploadingPhoto = false;
            });
        };

        // apply filter - dialog
        const modal = await modalController.create({
          component: ApplyFilterModal,
          mode: "md",
        });
        modal.componentProps = {
          imgSrc: this.currentGroupProfile.photo,
          parent: modal,
          filter_callback: filterCallback,
        };

        return modal.present();
      } catch (e) {
        this.uploadingPhoto = false;
        console.log("GPH takePhoto - err", e);
      }
    },
    async navigateManageGroup() {
      await this.router.push("/groups/" + this.group.group_id + "/manage");
    },
    async showZoomedPhoto(profilePhoto) {
      if (!profilePhoto) return;
      try {
        var imgSrc = profilePhoto;
        const modal = await modalController.create({
          component: PostImageModal,
          mode: "md",
        });
        modal.componentProps = {
          title: 'Profile Photo',
          imgSrc: imgSrc,
          parent: modal,
        };
        return modal.present();
      } catch (err) {
        console.log("profile photo err", err);
      }
    },
  },
});
</script>
