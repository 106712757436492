<template>
  <ion-button
    size="small"
    fill="clear"
    slot="end"
    shape="round"
    @click="openOptionsPopover"
    class="right-margin-15px"
    :disabled="group.info.visibility_type === 'Broadcast'"
  >
    <ion-icon
      size="small"
      color="dark"
      slot="icon-only"
      :icon="ellipsisHorizontal"
      v-if="group.info.visibility_type !== 'Broadcast'"
    ></ion-icon>
  </ion-button>
</template>

<script>

import { ellipsisHorizontal } from "ionicons/icons";

import {
  IonButton,
  IonIcon,
  popoverController,
} from "@ionic/vue";

import { defineComponent } from "vue";

import GroupPopoverOptions from "./GroupPopoverOptions.vue";
import { getStrings } from "../../services/lang";

export default defineComponent({
  name: "GroupPopoverButton",
  props: ["group"],
  components: {
    IonButton,
    IonIcon,
  },
  data() {
    return {
      ellipsisHorizontal,
      popoverController,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  setup() {
    return {
      getStrings,
    };
  },
  
  methods: {
    isAdmin() {
      return (
        this.group.info.is_group_member &&
        this.group.info.group_member_type === "Admin"
      );
    },
    async openOptionsPopover(ev) {
      const popover = await popoverController.create({
        component: GroupPopoverOptions  ,
        event: ev,
        translucent: true,
        cssClass: "userlist_popover"
      });

      var options = [];
      var muteGroupId = 'MUTE_GROUP';
      var muteGroupName = this.getStrings(this.strings, "NotificationOff");
      if (this.group.info.is_muted) {
        muteGroupId = 'UNMUTE_GROUP';
        muteGroupName = this.getStrings(this.strings, "NotificationOn");
      }

      options = [{ id: muteGroupId, name: muteGroupName },
                 { id: 'LEAVE_GROUP', name: this.getStrings(this.strings, "LeaveGroup")},
                 { id: 'REPORT', name: this.getStrings(this.strings, "Report")}];

      if(this.isAdmin()) {
        options.push({ id: 'MANAGE_GROUP', name: this.getStrings(this.strings, "ManageGroup")});
        options.push({ id: 'EDIT_GROUP', name: this.getStrings(this.strings, "EditGroup")});
      }
 
      popover.componentProps = {
          moreActions: options,
          group: this.group,
          parent: popover
        };
      return popover.present();
    },

  },
});
</script>
