<template>
  <ion-list>
    <ion-item v-for="member in group.members" v-bind:key="member.id">
      <ion-avatar
        slot="start"
        style="
          cursor: pointer;
          max-width: 30px;
          max-height: 30px;
          margin-top: 0px;
          margin-left: 15px;
          padding-top: 0px;
        "
        class="comment-avatar"
      >
        <ion-img
          style="
            max-width: 30px;
            max-height: 30px;
            margin-top: 10px;
            padding-top: 0px;
          "
          v-if="!member.profile_photo || !member.profile_photo.photo_thumbnail"
          src="assets/icon/misc/group.svg"
        ></ion-img>
        <ion-img
          style="
            max-width: 30px;
            max-height: 30px;
            margin-top: 10px;
            padding-top: 0px;
          "
          v-if="member.profile_photo && member.profile_photo.photo_thumbnail"
          :src="member.profile_photo && member.profile_photo.photo_thumbnail"
        ></ion-img>
      </ion-avatar>
      <ion-label color="dark" style="margin-left: 55px; min-height: 35px;">
        <small>
          <b style="cursor: pointer;"> {{  member.full_name }} </b><br/>
          <span style="color: #808080;" v-if="member.member_type === 'Admin'">Group Admin</span>
          <span style="color: #808080;" v-if="member.member_type !== 'Admin'">Group Member</span>
        </small>
      </ion-label>
      <ion-buttons class="right-margin-15px">
          <ion-button
            fill="outline"
            color="dark"
            size="small"
            shape="round"
            v-if="!member.isRemoved && member.member_type !== 'Admin' && isCurrentUserAdmin"
            @click="removeUser(member)"
            :disabled="removeUserClicked"
          >
              <small>{{ getStrings(strings, "Remove") }}</small>
          </ion-button>
          <ion-button
            size="small"
            fill="outline"
            color="dark"
            shape="round"
            v-if="member.member_type !== 'Admin' && isCurrentUserAdmin"
            @click="makeAdmin(member)"
            :disabled="removeUserClicked"
          >
            <small>{{ getStrings(strings, "MakeAdmin") }}</small>
          </ion-button>
      </ion-buttons>
      <ion-label slot="end" style="text-align: right" v-if="member.isRemoved">User Removed</ion-label>
      <ion-spinner
        v-if="removeUserClicked"
        slot="end"
        name="dots"
      ></ion-spinner>
    </ion-item>
  </ion-list>
</template>

<script>
import {
  IonList,
  IonItem,
  IonLabel,
  IonButtons,
  IonButton,
  IonSpinner,
  IonImg,
  IonAvatar,
  toastController,
  } from "@ionic/vue";
import { useRouter } from "vue-router";

import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
} from "../../services/utils";

import {
  removeGroupUser,
  makeGroupAdmin,
} from "../../services/apiCall";

import { getStrings } from "../../services/lang";

export default {
  name: "ManageGroupMembers",
  props: ["group", "refreshSettingCallback"],
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonButtons,
    IonButton,
    IonSpinner,
    IonImg,
    IonAvatar,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
      removeGroupUser,
      makeGroupAdmin,
      getStrings,
    };
  },
  data() {
    return {
      removeUserClicked: false,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
    currentGroupMember() {
      return this.$store.getters.currentGroupMember;
    },
    isCurrentUserAdmin() {
      return (
        this.group.info.is_group_member &&
        this.group.info.group_member_type === "Admin"
      );
    },
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    removeUser(member) {
      console.log("removeUser", member);
      this.removeGroupUserClicked = true;

      removeGroupUser({ group_id: this.group.group_id, member_id: member.member_id }).then((res) => {
        console.log('removeGroupUser', res);

        if (res.data.status === 0) {
          member.isRemoved = true;
          this.removeGroupUserClicked = false;
          this.refreshSettingCallback();
          this.openToast(this.getStrings(this.strings, "MemberRemovedFromGroup"));
        }
      }).catch((err) => {
        console.log('removeGroupUser - err', err);
        this.removeGroupUserClicked = false;
      });
    },
    makeAdmin(member) {
      console.log("makeAdmin", member);
      this.removeGroupUserClicked = true; // reused variable

      makeGroupAdmin({group_id: this.group.group_id, member_id: member.member_id}).then((res) => {
        console.log('makeGroupAdmin', res);

        if (res.data.status === 0) {
          member.member_type = 'Admin';
          this.removeGroupUserClicked = false;
          this.refreshSettingCallback();
          this.openToast(this.getStrings(this.strings, "MemberAdminNow"));
        }
      }).catch((err) => {
        console.log('makeGroupAdmin - err', err);
        this.removeGroupUserClicked = false;
      });
    },
  },
};
</script>