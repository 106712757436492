
<template>
  <!-- Update group -->
  <ion-item no-lines lines="none" class="ion-no-padding ion-no-pargin">
    <ion-label class="left-margin-15px"><b>{{ getStrings(strings, "UpdateGroup") }}</b></ion-label>
  </ion-item>
  <form @submit.prevent="handleGroupUpdate">
    <ion-item no-lines lines="none" class="signup-item-style">
      <ion-label class="left-margin-15px">{{ getStrings(strings, "GroupName") }}:</ion-label><br/>
      <ion-input
        v-model="form.groupName"
        id="groupName"
        class="right-margin-15px"
        required
      ></ion-input>
    </ion-item>
    <ion-item no-lines lines="none" class="signup-item-style">
      <ion-label class="left-margin-15px">{{ getStrings(strings, "GroupDescription") }}:</ion-label>
      <ion-textarea
        auto-grow="true"
        autocomplete="on"
        autocapitalize="on"
        spellcheck="true"
        v-model="form.groupDescription"
        id="groupDescription"
        required
        no-padding
        no margin
        class="right-margin-15px"
      ></ion-textarea>
    </ion-item>
    <ion-item no-lines lines="none" class="signup-item-style">
      <ion-label class="left-margin-15px">{{ getStrings(strings, "GroupVisibility") }}:</ion-label>
      <ion-select
        class="ion-padding-horizontal ion-no-margin"
        multiple="false"
        id="groupVisibiliy"
        interface="popover"
        v-model="form.groupVisibility"
      >
        <ion-select-option class="ion-padding-horizontal ion-no-margin" value="Public">{{ getStrings(strings, "Public") }}</ion-select-option>
        <ion-select-option class="ion-padding-horizontal ion-no-margin" value="Closed">{{ getStrings(strings, "ClosedGroup") }}</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item no-lines lines="none" class="signup-item-style">
      <ion-label class="left-margin-15px">{{ getStrings(strings, "MemberJoiningMode") }}:</ion-label>
      <ion-select
        class="ion-padding-horizontal ion-no-margin"
        multiple="false"
        id="groupMemberJoining"
        interface="popover"
        v-model="form.groupMemberJoining"
      >
        <ion-select-option class="ion-padding-horizontal ion-no-margin" value="Open">{{ getStrings(strings, "Open") }}</ion-select-option>
        <ion-select-option class="ion-padding-horizontal ion-no-margin" value="Request">{{ getStrings(strings, "Request") }}</ion-select-option>
        <ion-select-option class="ion-padding-horizontal ion-no-margin" value="Invitation">{{ getStrings(strings, "Invitation") }}</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item no-lines lines="none" class="signup-item-style">
      <ion-label class="left-margin-15px">{{ getStrings(strings, "IsGroupSearchable") }}?:</ion-label>
      <ion-checkbox
        id="groupSearchable"
        slot="end"
        style="margin-right: 20px"
        v-model="form.groupSearchable"
        required
      ></ion-checkbox>
    </ion-item>
    <ion-item no-lines lines="none">
      <ion-button
        size="medium"
        slot="end"
        :disabled="updateGroupClicked"
        type="submit"
        class="right-margin-15px"
      >
        {{ getStrings(strings, "UpdateGroup") }}
      </ion-button>
      <ion-spinner v-if="updateGroupClicked" name="dots"></ion-spinner>
    </ion-item>
  </form>
  <br/>
  <br/>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonButton,
  IonInput,
  IonTextarea,
  IonCheckbox,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  toastController,
} from "@ionic/vue";

import { useRouter } from "vue-router";
import { updateGroup, fetchGroup } from "../../services/apiCall";
import { getStrings } from "../../services/lang";

export default {
  name: "GroupUpdateForm",
  props: ["group"],
  ionViewWillEnter() {
    console.log("GroupUpdateForm will enter");
    this.init();
  },
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonTextarea,
    IonButton,
    IonCheckbox,
    IonSelect,
    IonSelectOption,
    IonSpinner,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      fetchGroup,
      getStrings,
    };
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      form: {
        groupName: "",
        groupDescription: "",
        groupVisibility: "Closed",
        groupMemberJoining: "Request",
        groupSearchable: false,
      },
      updateGroupClicked: false,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    init() {
      //this.fetchGroupInfo();
      console.log("========= Group From form:",this.group);
      this.form.groupName = this.group.info.group_name;
      this.form.groupDescription = this.group.info.description;
      this.form.groupVisibility = this.group.info.visibility_type;
      this.form.groupMemberJoining = this.group.info.joining_by;
      this.form.groupSearchable = this.group.info.is_searchable;
    },
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    handleGroupUpdate() {
      console.log("handleGroupUpdate");
      this.updateGroupClicked = true;
      updateGroup({
        group_id: this.group.group_id,
        group_name: this.form.groupName,
        description: this.form.groupDescription,
        is_searchable: this.form.groupSearchable,
        visibility_type: this.form.groupVisibility,
        joining_by: this.form.groupMemberJoining,
      })
        .then((res) => {
          if (res.data.status === 0) {
            this.openToast(this.getStrings(this.strings, "GroupDetailsUpdated"));
          } else {
            this.openToast(res.data.error.error_message);
          }

          this.updateGroupClicked = false;
        })
        .catch((err) => {
          console.log("createGroup - err", err);
          this.openToast(this.getStrings(this.strings, "GroupUpdateErrorTryAgain"));
          this.updateGroupClicked = false;
        });
    },
  },
};
</script>