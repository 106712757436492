<template>
  <base-layout
    :page-title="group.info.group_name"
    :showBackButton="showSystemBackButton"
    :enableHeaderShrink="true"
    :scrollCallback="onScroll"
    contentID="_groupDetailContent"
  >
    <ion-refresher
      slot="fixed"
      @ionRefresh="doRefresh($event)"
      :disabled="inPostRefreshMode"
    >
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-row class="ion-no-padding ion-no-pargin">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <group-overview
          :group="group"
          :refreshGroupCallback="refreshGroupCallback"
          :scrollState="scrollState"
          :pageAction="pageAction"
        ></group-overview>
      </ion-col>
    </ion-row>
    <ion-fab
      slot="fixed"
      vertical="bottom"
      horizontal="end"
      v-if="enableScrollToTop"
    >
      <ion-fab-button
        size="small"
        @click="scrollUpPosts()"
        translucent="true"
        class="animate-fading-1"
      >
        <ion-icon :icon="icon.arrowUpCircleOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </base-layout>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabButton,
  IonIcon,
  toastController,
} from "@ionic/vue";
import { useRouter } from "vue-router";
import { homeOutline } from "ionicons/icons";
import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
  defaultPostsPerFetch,
  processPreview,
  logOffApp,
} from "../services/utils";

import { setPosts, readFromDB } from "../services/db";

import { arrowUpCircleOutline } from "ionicons/icons";

import {
  fetchGroup,
  groupProfilePhoto,
  groupMemberList,
  joinRequestList,
  listPosts,
  fetchGroupMember,
} from "../services/apiCall";
import { getStrings } from "../services/lang";
import GroupOverview from "../components/groups/GroupOverview.vue";

export default {
  name: "GroupsDetailPage",
  ionViewDidEnter() {
    this.groupPageLeft = false;
  },
  ionViewDidLeave() {
    this.groupPageLeft = true;
  },
  ionViewWillEnter() {
    this.groupPageLeft = false;
    this.group.group_id = this.$route.params.groupid;
    this.readDBAndInit();
  },
  ionViewWillLeave() {
    this.groupPageLeft = true;
  },
  mounted() {
    this.groupPageLeft = false;
    this.group.group_id = this.$route.params.groupid;
  },
  watch: {
    "$route.params.groupid": function (groupid) {
      console.log("changed group", groupid);
      this.group.group_id = this.$route.params.groupid;
    },
    "$route.params.action": function (newaction) {
      console.log("changed newaction", newaction);
      this.pageAction = this.$route.params.action;
    },
  },
  components: {
    IonRow,
    IonCol,
    IonRefresher,
    IonRefresherContent,
    IonFab,
    IonFabButton,
    IonIcon,
    GroupOverview,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
      fetchGroup,
      groupProfilePhoto,
      groupMemberList,
      joinRequestList,
      defaultPostsPerFetch,
      fetchGroupMember,
      getStrings,
    };
  },
  data() {
    return {
      icon: {
        homeOutline,
        arrowUpCircleOutline,
      },
      group: {
        group_id: "",
        info: {},
        profile_photo: {},
        members: [],
        infoFetched: false,
        inPostRefreshMode: false,
      },
      scrollState: {
        scrollDisabled: true,
        scrollSkip: 0,
        isFavourite: false,
        topic_filter: [],
        user_id: "",
        group_id: this.$route.params.groupid,
        post_origin_tag: "Group",
      },
      pageAction: this.$route.params.action
        ? this.$route.params.action
        : "show",
      inPostRefreshMode: false,
      refreshEventObject: null,

      enableScrollToTop: false,

      groupPageLeft: false,
      showSystemBackButton: true,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    doRefresh(ev) {
      this.refreshEventObject = ev;
      this.init();
    },
    readDBAndInit(callbackObject = this) {
      this.$store.dispatch("setposts", []);

      this.group = {
        group_id: this.$route.params.groupid,
        info: {},
        profile_photo: {},
        members: [],
      };

      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            if (
              typeof callbackObject.user === "undefined" ||
              typeof callbackObject.user.user_id === "undefined"
            ) {
              this.logOff(callbackObject);
            } else {
              this.init();
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
            this.init();
          });
      } catch (err) {
        console.log("indexdb not available", err);
        this.init();
      }
    },
    async logOff(callbackObject = this) {
      this.openToast(
        this.getStrings(this.strings, "LoggingOffUserInvalidSession")
      );

      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },
    init() {
      // fetch group info
      this.group.infoFetched = false;
      fetchGroup({ group_id: this.group.group_id })
        .then((res) => {
          if (res.data.status === 0) {
            if (res.data.result.group.active_status === "Active") {
              this.group.info = res.data.result.group;
              this.group.infoFetched = true;
              if (res.data.result.group.visibility_type === "Professional_Page") {
                this.router.back();
                this.router.push(
                  "/page/" + res.data.result.group.tokenized_group
                );
              }
            } else {
              this.openToast(
                this.getStrings(this.strings, "GroupDeletedOrDeactivated")
              );
              this.router.replace("/posts");
            }
          }
        })
        .catch((err) => {
          console.log("fetchGroups - err", err);
        });

      // fetch group profile photo
      groupProfilePhoto({ group_id: this.group.group_id })
        .then((res) => {
          if (res.data.status === 0) {
            this.group.profile_photo = res.data.result.group_profile_photo;
          }
        })
        .catch((err) => {
          console.log("GDP  groupProfilePhoto - err", err);
        });

      // fetch group members
      groupMemberList({ group_id: this.group.group_id })
        .then((res) => {
          if (res.data.status === 0) {
            this.group.members = res.data.result.group_member_list;
          }
        })
        .catch((err) => {
          console.log("groupMemberList - err", err);
        });

      // fetch group join requests
      joinRequestList({ group_id: this.group.group_id })
        .then((res) => {
          if (res.data.status === 0) {
            this.group.join_requests = res.data.result.join_request_list;
          }
        })
        .catch((err) => {
          console.log("joinRequestList - err", err);
        });

      // fetch group posts
      var cond = {
        filtered_by: "Group",
        group_id: this.group.group_id,
        limit: defaultPostsPerFetch(),
        skip: 0,
      };

      this.group.inPostRefreshMode = true;
      this.$store.dispatch("setposts", []);
      listPosts(cond)
        .then(async (res) => {
          this.scrollState.scrollDisabled = false;
          this.scrollState.scrollSkip = 0;
          this.scrollState.post_origin_tag = "Group";
          this.scrollState.group_id = this.group.group_id;

          if (res.data.status === 0) {
            var processedPosts = res.data.result.post_list.map((onePost) => {
              var previewData = processPreview(onePost);
              onePost["previewData"] = previewData;
              onePost["post_origin_tag"] = "Group";
              return onePost;
            });

            if (!this.groupPageLeft) {
              this.$store.dispatch("setposts", processedPosts);
              await setPosts(processedPosts);
            }

            if (this.refreshEventObject !== null) {
              this.refreshEventObject.target.complete();
              this.refreshEventObject = null;
            }

            this.group.inPostRefreshMode = false;
          } else {
            if (this.refreshEventObject !== null) {
              this.refreshEventObject.target.complete();
              this.refreshEventObject = null;
            }

            this.group.inPostRefreshMode = false;
          }
        })
        .catch(async (err) => {
          console.log("listPosts - err", err);

          if (this.refreshEventObject !== null) {
            this.refreshEventObject.target.complete();
            this.refreshEventObject = null;
          }

          this.group.inPostRefreshMode = false;
        });

      // fetch group member
      fetchGroupMember({
        group_id: this.group.group_id,
        member_id: this.user.user_id,
      })
        .then((res) => {
          if (res.data.status === 0) {
            this.$store.dispatch(
              "setcurrentgroupmember",
              res.data.result.group_member
            );
          }
        })
        .catch((err) => {
          console.log("fetchGroupMember - err", err);
        });
    },
    refreshGroupCallback() {
      console.log("refreshGroupCallback");
      this.init();
    },
    scrollUpPosts() {
      let el = document.getElementById("_groupDetailContent");
      el.scrollToTop(300);
    },
    onScroll(ev) {
      // console.log('onScroll', ev);
      if (ev.detail.scrollTop > 250) {
        this.enableScrollToTop = true;
      } else {
        this.enableScrollToTop = false;
      }
    },
  },
};
</script>